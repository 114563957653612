@use "./src/shared/assets/scss/variables/index.scss" as *;
.jira-integration-create {
  width: 1154px;

  &__wrapper {
    display: flex;
  }

  &__left-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 48px;
    width: 100%;
  }

  &__left-container {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--grey20);
    border-bottom: 1px solid var(--grey20);
    padding-right: $indent-xxxl;
    padding-bottom: $indent-md;
    align-items: center;
    row-gap: $indent-xl;
  }

  &__connection-form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__login-block {
    display: flex;
    grid-gap: $indent-sm;
  }

  &__element {
    &_mb {
      margin-bottom: 18px;
    }

    &_one-of-two {
      flex-basis: 50%;
    }
  }

  &__check-connection {
    &-block {
      display: flex;
      align-items: center;
    }

    &-button {
      margin-right: $indent-xxl;
      min-width: 190px;
    }

    &-title-block {
      display: flex;
      align-items: center;
    }

    &-text {
      margin-left: $indent-md;
      color: var(--grey120);
    }

    &-icon {
      min-width: 24px;
      height: 24px;

      &_success {
        min-width: 16px;
        height: 16px;
      }
    }
  }

  &__select-connection-block {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-title {
      color: var(--grey200);
    }
  }

  &__right-container {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--grey20);
    padding-left: $indent-xxxl;
    padding-bottom: $indent-md;
    align-items: center;
    row-gap: $indent-lg;
  }

  &__title {
    flex-basis: 50%;
    color: var(--grey120);

    &:nth-child(2) {
      margin-left: 40px;
    }

    &-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__map {
    &-wrapper {
      height: 224px;
      overflow-y: auto;

      &_empty {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &-block {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-title {
      color: var(--grey200);
      margin-top: $indent-md;
      margin-bottom: $indent-md;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: $indent-md;
      }
    }

    &-select-wrapper {
      display: flex;
      align-items: center;
    }

    &-icon {
      margin-right: $indent-lg;
      fill: var(--grey100);
      stroke: var(--grey100);
    }

    &-select {
      width: 239px;
    }

    &-priority {
      font-size: $sm-text;
    }
  }

  &__select {
    &-type {
      width: 215px;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 90%;

    &__wrapper {
      flex-direction: column;
    }

    &__left-container {
      border-right: none;
      padding-right: 0;
      padding-bottom: $indent-md;
    }

    &__right-container {
      border-bottom: 1px solid var(--grey20);
      padding-left: 0;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    &__check-connection {
      &-block {
        flex-direction: column;
        align-items: flex-start;
      }

      &-button {
        margin-bottom: $indent-sm;
      }
    }
  }

  @media screen and (max-width: 576px) {
    &__map {
      &-select {
        width: 20%;
        min-width: 149px;
      }

      &-status {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 230px;
        margin-right: $indent-lg;
      }

      &-priority {
        min-width: auto;
        margin-right: $indent-lg;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  @media screen and (max-width: 360px) {
    &__map {
      &-select {
        min-width: 110px;
      }

      &-status {
        max-width: 149px;
      }

      &-priority {
        max-width: 149px;
      }
    }
  }
}
