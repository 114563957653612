@use "./src/shared/assets/scss/variables/index.scss" as *;
.storage-add-files {
  display: flex;
  flex-direction: column;
  grid-gap: $indent-xl;

  &__icon {
    margin-right: 0;
  }

  &__cancel-button {
    display: none;
  }

  &__warning {
    display: flex;
    align-items: center;
    grid-gap: $indent-md;
    height: 48px;
    padding-left: $indent-sm;
    margin-bottom: $indent-sm;
    border-radius: $border-radius-sm;
    background-color: var(--yellow20);

    &-icon {
      color: var(--yellow140);
    }

    &-text {
      color: var(--yellow170);
    }
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    padding-left: $indent-md;
    margin-bottom: $indent-sm;
    border-radius: $border-radius-sm;
    background-color: var(--red20);

    &-block {
      display: flex;
      align-items: center;
      grid-gap: $indent-md;
    }

    &-button {
      border: none;
      margin-right: $indent-sm;

      &-icon {
        color: var(--grey100);
      }
    }

    &-icon {
      color: var(--red80);
    }

    &-text {
      color: var(--red170);
    }
  }
}
