@use "./src/shared/assets/scss/variables/index.scss" as *;
.storage-downloading-file {
  height: 52px;
  display: flex;
  align-items: center;
  grid-gap: $indent-lg;
  width: 100%;
  margin-bottom: $indent-sm;

  &__title {
    display: flex;
    align-items: center;
    height: 100%;
    color: var(--grey100);
  }

  &__icon {
    color: var(--grey100);
    width: 16px;
    height: 16px;
    margin-right: $indent-xxs;
  }

  &__arrow-icon {
    position: absolute;
    height: 16px;
    width: 16px;
    z-index: 1;
    transform: rotate(90deg);
    color: var(--blue120);
  }

  &__progress {
    width: 24px;
    height: 24px;
    border-radius: 50%;

    &-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      stroke: var(--blue120);
      stroke-width: 18px;
      fill: transparent;
      transform-origin: center;
      transform: rotate(-90deg);
      transition: stroke-dashoffset 0.3s;
    }
  }
}
