@use "./src/shared/assets/scss/variables/index.scss" as *;
.tag {
  --color-scheme: var(--grey-20);
  --color-text: var(--grey-200);

  $this: &;

  display: inline-flex;
  gap: 0 var(--indent-4pt);
  align-items: center;
  border-radius: var(--border-radius-4);

  &_fill {
    background-color: var(--color-scheme);
    color: var(--color-text);
    #{$this}_disabled {
      --color-scheme: var(--gray-20);
    }
  }

  &_outline {
    color: var(--color-text);

    &::before {
      border-radius: var(--border-radius-4);
      border: 1px solid var(--border-color);
      transition: border var(--transition-delay);
      position: absolute;
      content: '';
      inset: 0;
    }

    #{$this}_disabled {
      --color-scheme: var(--gray-30);
    }
  }

  &_purple {
    --color-scheme: var(--purple-20);
    --color-text: var(--purple-140);
  }

  &_blue {
    --color-scheme: var(--blue-20);
    --color-text: var(--blue-140);
  }

  &_cyan {
    --color-scheme: var(--cyan-20);
    --color-text: var(--cyan-140);
  }

  &_green {
    --color-scheme: var(--green-20);
    --color-text: var(--green-140);
  }

  &_yellow {
    --color-scheme: var(--yellow-20);
    --color-text: var(--yellow-140);
  }

  &_orange {
    --color-scheme: var(--orange-20);
    --color-text: var(--orange-140);
  }

  &_red {
    --color-scheme: var(--red-20);
    --color-text: var(--red-140);
  }

  &_grey {
    --color-scheme: var(--grey-20);
    --color-text: var(--grey-200);
  }

  &_disabled {
    --color-text: var(--gray-80);
  }

  &_s {
    height: 24px;
    padding: 0 var(--indent-8pt);
  }

  &_xs {
    height: 20px;
    padding: 0 6px;
  }
}
