@use "./src/shared/assets/scss/variables/index.scss" as *;
.storage-downloaded-file {
  height: 52px;
  display: flex;
  align-items: center;
  width: 100%;

  &__container {
    margin-bottom: $indent-sm;

    &_active {
      padding: 0 $indent-xs $indent-xs;
      background-color: var(--blue10);
      border-radius: $border-radius-sm;
      border: 1px solid var(--blue80);
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 52px;
  }

  &__inputs-container {
    display: flex;
    flex-direction: column;
    gap: $indent-sm;
  }

  &__custom-field {
    &-icon {
      color: var(--grey100);
    }
  }

  &__flag {
    display: flex;
    column-gap: $indent-xxs;
  }

  &__block {
    display: flex;
    align-items: center;
    grid-gap: $indent-sm;
  }

  &__title {
    display: flex;
    align-items: center;
    height: 100%;

    &-container {
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  }

  &__file-type {
    color: var(--grey100);
  }

  &__button {
    border: none;
  }

  &__icon {
    color: var(--grey100);
    width: 16px;
    height: 16px;
    margin-right: $indent-xxs;

    &-star {
      width: 20px;
      height: 20px;

      &_no-active {
        color: var(--grey100);
      }

      &_active {
        color: var(--yellow120);
        fill: var(--yellow120);
      }
    }

    &-loading {
      color: var(--blue120);
    }

    &-delete {
      color: var(--red80);
    }
  }
}
