@use "./src/shared/assets/scss/variables/index.scss" as *;
.jira-integrations-current {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: height $transition ease-in-out;
  position: relative;

  &__edit-block {
    --header-height: 64px;

    height: 100%;
    min-width: 420px;
    position: relative;

    &-header {
      align-items: center;
      background-color: var(--grey10);
      border-bottom: 1px solid var(--grey20);
      border-radius: $border-radius $border-radius 0 0;
      display: flex;
      justify-content: space-between;
      min-height: var(--header-height);
      padding: 0 $indent-xl;
    }

    &-container {
      display: flex;
      height: calc(100% - var(--header-height));
      justify-content: center;
      background: var(--common2);
    }

    &-buttons {
      display: flex;
      justify-content: flex-end;
      padding: $indent-md $indent-xl $indent-xl $indent-xl;
      column-gap: $indent-sm;
    }

    &-footer {
      border-top: 1px solid var(--grey20);
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      background-color: var(--common2);
    }
  }

  &_close {
    height: $table-header-height;
    overflow: hidden;
  }

  &__content {
    padding: $indent-xl;
    flex-basis: 100%;
    background-color: var(--common2);
    border-radius: 0 0 $border-radius $border-radius;
    overflow: auto;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: $indent-xl;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    row-gap: $indent-md;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: $indent-xs;

    &-element {
      display: flex;
      align-items: center;
    }

    &-title {
      color: var(--grey200);
      margin-left: $indent-sm;
    }

    &-icon {
      color: var(--cyan100);
      width: 24px;
      height: 24px;
    }
  }

  &__info-line-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input {
    width: 42%;
  }

  &__fields-list-wrapper {
    margin-top: 40px;
  }

  &__alert {
    max-width: 100%;
    min-width: 100%;
  }

  &__empty-data {
    height: 100%;
  }

  &__edit-icon {
    color: var(--grey80);
  }

  @media screen and (max-width: 768px) {
    &__content {
      padding: $indent-xl $indent-sm;
    }
  }
}
