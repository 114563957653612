@use "./src/shared/assets/scss/variables/index.scss" as *;
.notifications-profile {
  flex-basis: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  grid-gap: $indent-sm;

  &__arrows {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
    grid-gap: $indent-xs;
    height: fit-content;

    &-item {
      width: 10px;
      height: 24px;

      &_left {
        transform: rotate(180deg);
      }
    }
  }

  &__tabs {
    display: flex;
    flex-direction: row;
    padding: $indent-lg $indent-xl 0 $indent-xl;
    box-shadow: inset 0 -2px 0 0 var(--grey20);
    width: 100%;
    position: relative;
    justify-content: space-between;
    min-height: 60px;
    max-height: 60px;

    &-item {
      padding-top: 10px;
    }
  }

  &__tab-line {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    width: calc(100% - 52px);

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }
  }

  &__content {
    flex-basis: 74%;
    min-width: 300px;
    background-color: var(--common2);
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    display: flex;
    flex-direction: column;
  }

  &__ticket {
    max-width: 450px;
    display: flex;
    overflow-y: auto;
  }

  @media screen and (max-width: 1200px) {
    &__content {
      flex-basis: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      border-radius: 0;
    }

    &__tabs {
      padding: $indent-md $indent-md 0 $indent-md;
    }
  }
}
