@use "./src/shared/assets/scss/variables/index.scss" as *;
.storage-create {
  height: 100vh;
  width: 100%;

  &__inputs {
    &-container {
      display: flex;
      flex-direction: column;
      row-gap: $indent-lg;
    }
  }

  &__container {
    background-color: var(--common2);
    box-shadow: var(--box-shadow-lvl-2);
    border-radius: $border-radius 0 0 $border-radius;
    width: 420px;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100vh;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: $indent-xl;
    padding-right: $indent-lg;
    height: 65px;
    background-color: var(--grey10);
    border-radius: $border-radius 0 0 0;

    &-close-icon {
      color: var(--grey100);
    }
  }

  &__size {
    &-container {
      display: flex;
      flex-direction: column;
      row-gap: $indent-xl;
    }

    &-title {
      color: var(--grey120);
    }

    &-title-container {
      height: 36px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: $border-radius-sm;
      background-color: var(--blue10);
    }

    &-toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: $indent-md;
    }

    &-range {
      column-gap: $indent-xs;
    }

    &-input {
      width: 100%;
    }

    &-max-min-container {
      display: flex;
      justify-content: space-between;
    }

    &-max-min {
      color: var(--grey100);
    }
  }

  &__attributes {
    display: flex;
    flex-direction: column;
    row-gap: $indent-xs;

    &-title {
      padding: $indent-xs 0;

      &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &-tags {
      display: flex;
      flex-wrap: wrap;
      gap: $indent-xs;
    }
  }

  &__add-attribute {
    display: flex;
    flex-direction: column;
    row-gap: $indent-lg;

    &-title {
      color: var(--grey100);
    }
  }

  &__arrow-button {
    color: var(--blue120);
    display: flex;
    align-items: center;
    column-gap: $indent-xxs;
    padding: $indent-xs;

    &-icon {
      width: 20px;
      height: 20px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    row-gap: $indent-lg;
    height: 100%;
    padding: $indent-lg $indent-xl;
    overflow-x: auto;
  }

  &__buttons {
    display: flex;
    column-gap: $indent-xs;
    margin-left: $indent-lg;
    margin-bottom: $indent-lg;
  }

  @media screen and (max-width: 768px) {
    &__container {
      width: 100%;
    }
  }
}
